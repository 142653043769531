import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClientModule} from "@angular/common/http";
import {LayoutComponent} from "./layout/layout.component";
import {AuthGuard} from "./guard/AuthGuard";


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/tenants'
  },
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {path: 'tenants', loadChildren: () => import('./tenants/tenants.module').then(m => m.TenantsModule)},
      {path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)},

    ],
  },
];
const routerConfig: ExtraOptions = {
  // preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, routerConfig),

  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import {Component} from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {Observable} from "rxjs";
import {GlobalLoaderService} from "./service/global-loader.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'triton-backoffice';
  showLoading: Observable<boolean>

  constructor(private kc: KeycloakService, private globalLoader: GlobalLoaderService) {
    this.showLoading = this.globalLoader.showLoading$;
    // const test  = kc.isUserInRole("operator")
    // console.log("Operator?", test)
    // this.kc.getToken().then(token => {
    //   const decoded = jwtDecode(token);
    //   console.log("decoded", decoded['auth_claims'])
    // })

  }

}

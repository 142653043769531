import {Component} from '@angular/core';
import {faAngleDown, faCircle, faGear, faHouse, faMap, faSailboat} from "@fortawesome/free-solid-svg-icons";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";


interface MenuItem {
  id: string,
  name: string,
  url?: string,
  icon?: IconDefinition,
  children?: MenuItem[],
  selected?: boolean
  open?: boolean
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent {
  currentActive: MenuItem[] = []
  menuItems: MenuItem[] = [


    {
      id: 'Clienti',
      name: 'Clienti',
      url: '/tenants',
      icon: faMap,
      children: null
    },
    {
      id: 'Impostazioni',
      name: 'Impostazioni',
      icon: faGear,
      children: [
        {
          id: 'gitlabSecrets',
          name: 'Deploy secrets',
          url: '/settings/secrets',
          icon: null,
        }
      ]
    },

  ]


  constructor(private router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.enableHierarchy(this.menuItems, this.router.url)
      });
  }

  setMenu(item: MenuItem) {
    item.open = !item.open
    this.enableHierarchy(this.menuItems, item.url)
  }

  enableHierarchy(items: MenuItem[], url: string) {
    if (!url || !items)
      return
    let found = null;
    for (let node of items) {
      node.selected = false;
      node.open = false;
      if (url.startsWith(node.url)) {
        found = node;
      } else {
        const c = this.enableHierarchy(node.children, url);
        if (c?.selected) {
          node.selected = true;
          node.open = true;
          found = node;
        }
      }
    }
    if(found) {
      found.selected = true
    }
    return found


  }


  protected readonly faAngleDown = faAngleDown;
}

import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthService} from "./service/auth.service";
import {environment} from "../environments/environment";
import {ApolloModule} from "apollo-angular";
import {LayoutComponent} from './layout/layout.component';
import {HeaderComponent} from './layout/header/header.component';
import {MenuComponent} from './layout/menu/menu.component';
import {MatIconModule} from "@angular/material/icon";
import {IconsModule} from "./icons/icons.module";
import {AvatarComponent} from './layout/avatar/avatar.component';
import {MatMenuModule} from '@angular/material/menu';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatSuffix} from "@angular/material/form-field";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {TitleCasePipe, UpperCasePipe} from "@angular/common";
import {ToastrModule} from "ngx-toastr";
import {KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService} from "keycloak-angular";
import {NgxEchartsModule} from "ngx-echarts";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgEventBus} from "ng-event-bus";
import {LogoComponent} from "./layout/logo/logo.component";
import {ComponentsModule} from "./components/components.module";
import {NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {NgbDateCustomParserFormatter} from "./components/custom-date-parser";
import {NgSelectModule} from "@ng-select/ng-select";

function initializeKeycloak(keycloak: KeycloakService) {
  return () => {
    const token = localStorage.getItem('kc_token');
    const refreshToken = localStorage.getItem('kc_refresh_token');
    const idToken = localStorage.getItem('kc_id_token');
    return keycloak.init({
      config: {
        url: environment.keycloak.url,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.clientId,

      },
      initOptions: {
        onLoad: 'check-sso',
        adapter: 'default',
        checkLoginIframe: true,
        redirectUri: window.location.origin + '/tenants',
        pkceMethod: 'S256',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
        token: token,
        refreshToken: refreshToken,
        idToken: idToken
      },

    }).then(x => {
      if (x) {
        const idToken = keycloak.getKeycloakInstance().idToken;
        const refreshToken = keycloak.getKeycloakInstance().refreshToken;
        const token = keycloak.getKeycloakInstance().token;
        localStorage.setItem('kc_token', token);
        localStorage.setItem('kc_refresh_token', refreshToken);
        localStorage.setItem('kc_id_token', idToken);
      }
      return x
    });
    ;
  }
}


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    LogoComponent,
    MenuComponent,
    AvatarComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    IconsModule,
    MatIconModule,
    MatMenuModule,
    NgSelectModule,
    MatSuffix,
    ApolloModule,
    MatNativeDateModule,
    FontAwesomeModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    ComponentsModule,
    NgbModule,
    MatIconButton,
    MatTooltip
  ],
  providers: [
    AuthService,
    TitleCasePipe,
    UpperCasePipe,
    MatNativeDateModule,
    // {provide: FormioAppConfig, useValue: AppConfig},
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true
    },

    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    NgEventBus,
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},


  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
